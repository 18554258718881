<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="12"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Promosi
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Promosi
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <input type="hidden" name="id" id="id" v-model="promosi.id"/>
                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-text-field
                                            class="purple-input"
                                            label="Nama"
                                            v-model="promosi.name"
                                    />
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTglMulai"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormattedMulai"
                                                    class="purple-input"
                                                    label="Tanggal Mulai"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="start_dt" @input="menuTglMulai = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTglSelesai"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormattedSelesai"
                                                    class="purple-input"
                                                    label="Tanggal Selesai"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="end_dt" @input="menuTglSelesai = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!--<v-col-->
                                <!--cols="12" md="1">-->
                                <!--<v-btn color="secondary" raised x-small dark v-on:click="openModalKategori">-->
                                <!--<v-icon>mdi-television</v-icon>-->
                                <!--</v-btn>-->
                                <!--</v-col>-->

                                <v-col
                                        cols="12"
                                        md="4"

                                >
                                    <v-select
                                            label="Tipe"
                                            class="purple-input"
                                            v-model="promosi.type"
                                            :items="list_types"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                        v-if="promosi.type == 'Disc Persen'"
                                >
                                    <v-text-field
                                            label="Persen"
                                            class="purple-input"
                                            type="number"
                                            suffix="%"
                                            v-model="promosi.percent"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                        v-if="promosi.type == 'Disc Persen'"
                                >
                                    <v-text-field
                                            label="Maksimal Potongan"
                                            class="purple-input"
                                            prefix="Rp"
                                            v-model="promosi.max_amount"
                                    />
                                    <input type="hidden" v-model="promosi.max_amount" v-money="money"/>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                        v-if="promosi.type == 'Buy & Get'"
                                >
                                    <v-text-field
                                            label="Buy"
                                            class="purple-input"
                                            type="number"
                                            v-model="promosi.buy"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                        v-if="promosi.type == 'Buy & Get'"
                                >
                                    <v-text-field
                                            label="Get"
                                            class="purple-input"
                                            type="number"
                                            v-model="promosi.get"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="6"
                                        v-if="promosi.type == 'Disc Harga'"
                                >
                                    <v-text-field
                                            label="Harga"
                                            class="purple-input"
                                            prefix="Rp"
                                            v-model="promosi.amount"
                                    />
                                    <input type="hidden" v-model="promosi.amount" v-money="money"/>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="4"
                                        v-if="promosi.type == 'Paket'"
                                >
                                    <v-textarea
                                            label="Deskripsi"
                                            class="purple-input"
                                            v-model="promosi.desc"
                                    />
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="4"
                                        v-if="promosi.type != 'Paket'"
                                >
                                    <v-textarea
                                            label="Deskripsi"
                                            class="purple-input"
                                            v-model="promosi.desc"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                        v-if="promosi.type == 'Disc Persen' || promosi.type == 'Disc Harga'"
                                >
                                    <v-checkbox
                                            v-model="promosi.is_all_item"
                                            label="Semua Item"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>

                                <!--<v-col-->
                                <!--cols="12"-->
                                <!--md="5"-->
                                <!--justify="center"-->
                                <!--&gt;-->
                                <!--<v-file-input-->
                                <!--label="Gambar"-->
                                <!--class="purple-input"-->
                                <!--v-model="produk.image"-->
                                <!--accept="image/*"-->
                                <!--@change="onFileChange"-->
                                <!--&gt;-->
                                <!--<template v-slot:selection="{text}">-->
                                <!--<v-chip-->
                                <!--label-->
                                <!--color="primary">-->
                                <!--{{text}}-->
                                <!--</v-chip>-->
                                <!--</template>-->
                                <!--</v-file-input>-->
                                <!--<v-img position="center center" v-if="produk.image_path !== '' && produk.image_path" :src="showImgProduct"-->
                                <!--max-width="150" max-height="150" ratio="1" contain/>-->
                                <!--</v-col>-->

                                <v-col
                                        cols="12"
                                        :md="promosi.type == 'Buy & Get' ? 6 : 12"
                                        v-if="!promosi.is_all_item"
                                >
                                    <base-subheading subheading="Daftar Promosi Produk"/>
                                    <v-col
                                            cols="12"
                                            md="12"
                                    >
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="primary--text text-center" width="50">
                                                        No
                                                    </th>
                                                    <th class="primary--text text-left">
                                                        Nama
                                                    </th>
                                                    <th class="primary--text text-left" width="150">
                                                        Tipe
                                                    </th>
                                                    <th class="primary--text text-center" width="100">
                                                        Hapus
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr v-for="(item, index) in promosi.list_products" :key="index">
                                                    <td class="text-center">{{(index + 1)}}.
                                                        <input type="hidden" name="id_concotion"
                                                               id="id_list_product" v-model="item.id"/>
                                                        <input type="hidden" name="product_id" id="product_id"
                                                               v-model="item.fk_product_id"/>
                                                        <input type="hidden" name="product_category_id"
                                                               id="product_category_id"
                                                               v-model="item.fk_product_category_id"/>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                                class="purple-input"
                                                                readonly
                                                                v-model="item.product_name"
                                                                v-if="item.type == 'Produk'"
                                                        >
                                                            <v-icon slot="append" color="green" size="30"
                                                                    @click="openModalProduk(index, 'Paid')">mdi-magnify
                                                            </v-icon>
                                                            <!--<span slot="append" color="red" size="30"-->
                                                            <!--@click="openModalProduk(index)">open-->
                                                            <!--</span>-->
                                                        </v-text-field>
                                                        <v-text-field
                                                                class="purple-input"
                                                                readonly
                                                                v-if="item.type == 'Kategori'"
                                                                v-model="item.category_name"
                                                        >
                                                            <v-icon slot="append" color="green" size="30"
                                                                    @click="openModalKategori(index, 'Paid')">
                                                                mdi-magnify
                                                            </v-icon>
                                                            <!--<span slot="append" color="red" size="30"-->
                                                            <!--@click="openModalProduk(index)">open-->
                                                            <!--</span>-->
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-select
                                                                class="purple-input"
                                                                v-model="item.type"
                                                                :items="['Produk', 'Kategori']"
                                                        />
                                                    </td>
                                                    <td class="text-center">
                                                        <v-icon
                                                                color="error"
                                                                class="mx-1"
                                                                @click="hapus(index)"
                                                        >
                                                            mdi-close
                                                        </v-icon>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            class="text-center"
                                    >
                                        <v-btn
                                                color="success"
                                                class="mr-0"
                                                @click="tambahProduk"
                                        >
                                            <v-icon left>mdi-plus</v-icon>
                                            Tambah
                                        </v-btn>
                                    </v-col>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="6"
                                        v-if="promosi.type == 'Buy & Get'"
                                >
                                    <base-subheading subheading="Daftar Produk Free"/>
                                    <v-col
                                            cols="12"
                                            md="12"
                                    >
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="primary--text text-center" width="50">
                                                        No
                                                    </th>
                                                    <th class="primary--text text-left">
                                                        Nama
                                                    </th>
                                                    <th class="primary--text text-left" width="150">
                                                        Tipe
                                                    </th>
                                                    <th class="primary--text text-center" width="100">
                                                        Hapus
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr v-for="(item, index) in promosi.list_products_free" :key="index">
                                                    <td class="text-center">{{(index + 1)}}.
                                                        <input type="hidden" name="id_concotion"
                                                               id="id_list_free" v-model="item.id"/>
                                                        <input type="hidden" name="free_product_id" id="free_product_id"
                                                               v-model="item.fk_product_id"/>
                                                        <input type="hidden" name="fee_product_category_id"
                                                               id="free_product_category_id"
                                                               v-model="item.fk_product_category_id"/>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                                class="purple-input"
                                                                readonly
                                                                v-model="item.product_name"
                                                                v-if="item.type == 'Produk'"
                                                        >
                                                            <v-icon slot="append" color="green" size="30"
                                                                    @click="openModalProduk(index, 'free')">mdi-magnify
                                                            </v-icon>
                                                            <!--<span slot="append" color="red" size="30"-->
                                                            <!--@click="openModalProduk(index)">open-->
                                                            <!--</span>-->
                                                        </v-text-field>
                                                        <v-text-field
                                                                class="purple-input"
                                                                readonly
                                                                v-if="item.type == 'Kategori'"
                                                                v-model="item.category_name"
                                                        >
                                                            <v-icon slot="append" color="green" size="30"
                                                                    @click="openModalKategori(index, 'free')">
                                                                mdi-magnify
                                                            </v-icon>
                                                            <!--<span slot="append" color="red" size="30"-->
                                                            <!--@click="openModalProduk(index)">open-->
                                                            <!--</span>-->
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-select
                                                                class="purple-input"
                                                                v-model="item.type"
                                                                :items="['Produk', 'Kategori']"
                                                        />
                                                    </td>
                                                    <td class="text-center">
                                                        <v-icon
                                                                color="error"
                                                                class="mx-1"
                                                                @click="hapusFree(index)"
                                                        >
                                                            mdi-close
                                                        </v-icon>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            class="text-center"
                                    >
                                        <v-btn
                                                color="success"
                                                class="mr-0"
                                                @click="tambahProdukFree"
                                        >
                                            <v-icon left>mdi-plus</v-icon>
                                            Tambah
                                        </v-btn>
                                    </v-col>
                                </v-col>


                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="simpan"
                                    >
                                        Simpan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="modalKategori" persistent min-width="350" max-width="50%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">
                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Kategori Produk
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalKategori = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchKategori"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersKategori"
                                            :items="itemsKategori"
                                            :search="searchKategori"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihKategori(item)">
                                                mdi-check-bold
                                            </v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalKategori = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="modalProduk" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Produk
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalProduk = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchProduk"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersProduk"
                                            :items="itemsProduk"
                                            :search="searchProduk"
                                    >
                                        <template v-slot:item.retail_price="{ item }">
                                            <input type="hidden" v-model.lazy="item.retail_price" v-money="money"/>
                                            {{item.retail_price}}
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihProduk(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihProduk(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalProduk = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import VueNumeric from 'vue-numeric'
    import {Money} from 'v-money'

    export default {
        name: 'FormPromotion',
        components: {
            Money
        },
        data() {
            return {
                date: new Date().toISOString().substr(0, 10),
                dateFormattedMulai: '',
                dateFormattedSelesai: '',
                menuTglMulai: false,
                menuTglSelesai: false,
                modal: false,
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                indexPilih: 0,
                tipeModal: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: true /* doesn't work with directive */
                },
                list_types: ['Disc Persen', 'Disc Harga', 'Buy & Get', 'Paket'],
                start_dt: new Date().toISOString().substr(0, 10),
                end_dt: new Date().toISOString().substr(0, 10),
                promosi: {
                    id: null,
                    list_products: [
                        {
                            id: null,
                            fk_product_id: 0,
                            fk_product_category_id: 0,
                            product_name: '',
                            category_name: '',
                            type: 'Produk',
                            is_free: 0,
                            fk_promotion_id: 0
                        }
                    ],
                    list_products_free: [
                        {
                            id: null,
                            fk_product_id: 0,
                            fk_product_category_id: 0,
                            product_name: '',
                            category_name: '',
                            type: 'Produk',
                            is_free: 0,
                            fk_promotion_id: 0
                        }
                    ],
                    name: '',
                    desc: '',
                    type: 'Disc Persen',
                    is_all_item: false,
                    percent: 0,
                    max_amount: 0,
                    amount: 0,
                    buy: 0,
                    get: 0,
                    start_date: new Date().toISOString().substr(0, 10),
                    end_date: new Date().toISOString().substr(0, 10),
                },
                deletedItems: [],
                modalProduk: false,
                searchProduk: '',
                headersProduk: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Kategori',
                        filterable: true,
                        value: 'category_name',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Harga',
                        filterable: true,
                        value: 'retail_price',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Satuan',
                        filterable: true,
                        value: 'uom_name',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsProduk: [],
                modalKategori: false,
                searchKategori: '',
                headersKategori: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Kode',
                        filterable: true,
                        value: 'code',
                        width: 70,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Pilih',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsKategori: [],
                isLoadData: false,
                prices: 0,
                strprice: ''
            }
        },
        computed: {
            ...mapState(['currentUser']),
            computedDateFormatted() {
                console.log('date formatted')
                this.dateFormattedMulai = this.formatDate(this.start_dt)
                this.dateFormattedSelesai = this.formatDate(this.end_dt)
            },

        },
        watch: {
//            produk: {
//                handler: function (val, oldVal) {
////                    let t = this
////                    console.log(t.prices)
//                    val.forEach(function (item, i) {
////                        t.strprice = item.wholesale_prices.toString()
////                        console.log(t.strprice)
////                        console.log('index '+i)
////                        t.prices = t.strprice.replace(".","")
////                        console.log(prices, prices*2)
////                        this.produk.retail_price = this.produk.retail_price.replace(/\./g,'')
//                        item.subtotal = parseFloat(item.qty.toString().replace(/\./g,'')) * parseFloat(item.wholesale_prices.toString().replace(/\./g,''))
////                        console.log('subtotal '+item.subtotal, 'qty '+item.qty, 'prices '+t.prices)
//                    })
//                },
//                deep: true
//            },
            start_dt: function (val) {
                this.dateFormattedMulai = this.formatDate(val)
            },
            end_dt: function (val) {
                this.dateFormattedSelesai = this.formatDate(val)
            }
        },
        mounted: function () {
            this.dataKategori()
            this.dataProduk()
            this.computedDateFormatted
            let id = this.$route.params.id
            console.log('edit')
            console.log(id)
            console.log(this.produk)
            if (id !== undefined) {
                this.dataPromosi(id)
            }
        },
        methods: {
            formatDate: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            openModalProduk: function (i, tipe) {
                this.modalProduk = true
                this.indexPilih = i
                this.tipeModal = tipe
            },
            dataProduk: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'products', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        this.isLoadData = true
                        if (response.data.success) {
                            this.itemsProduk = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihProduk: function (item) {
                console.log('pilih produk')
                console.log(item)
                if (this.tipeModal == 'Paid') {
                    this.promosi.list_products[this.indexPilih].category_name = item.category_name
                    this.promosi.list_products[this.indexPilih].product_name = item.name
                    this.promosi.list_products[this.indexPilih].fk_product_id = item.id
                } else {
                    this.promosi.list_products_free[this.indexPilih].category_name = item.category_name
                    this.promosi.list_products_free[this.indexPilih].product_name = item.name
                    this.promosi.list_products_free[this.indexPilih].fk_product_id = item.id
                }
                this.modalProduk = false
            },
            openModalKategori: function (i, tipe) {
                this.modalKategori = true
                this.indexPilih = i
                this.tipeModal = tipe
            },
            dataKategori: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                console.log('load kategori')
                console.log(this.itemsKategori)
                axios
                ({method: 'get', url: ApiUrl + 'productscategory', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.itemsKategori = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihKategori: function (item) {
                console.log('pilih kategori')

                if (this.tipeModal == 'Paid') {
                    this.promosi.list_products[this.indexPilih].category_name = item.name
                    this.promosi.list_products[this.indexPilih].fk_product_category_id = item.id
                } else {
                    this.promosi.list_products_free[this.indexPilih].category_name = item.name
                    this.promosi.list_products_free[this.indexPilih].fk_product_category_id = item.id
                }

                this.modalKategori = false
            },
            tambahProduk: function () {
                console.log(this.promosi.list_products)
                this.promosi.list_products.push({
                    id: null,
                    fk_product_id: 0,
                    fk_product_category_id: 0,
                    product_name: '',
                    category_name: '',
                    type: 'Produk',
                    is_free: 0,
                    fk_promotion_id: this.promosi.id ? this.promosi.id : 0
                })
            },
            hapus: function (index) {
                if (this.promosi.list_products[index].id) {
                    this.deletedItems.push(this.promosi.list_products[index].id)
                }

                if (this.promosi.list_products.length > 1)
                    this.promosi.list_products.splice(index, 1)
                else {
                    this.promosi.list_products[index].id = null
                    this.promosi.list_products[index].fk_product_id = 0
                    this.promosi.list_products[index].product_name = ''
                    this.promosi.list_products[index].category_name = ''
                    this.promosi.list_products[index].fk_product_category_id = 0
                    this.promosi.list_products[index].fk_promotion_id = this.promosi.id ? this.promosi.id : 0
                    this.promosi.list_products[index].is_free = 0
                    this.promosi.list_products[index].type = 'Produk'
                }


            },
            tambahProdukFree: function () {
                console.log(this.promosi.list_products_free)
                this.promosi.list_products_free.push({
                    id: null,
                    fk_product_id: 0,
                    fk_product_category_id: 0,
                    product_name: '',
                    category_name: '',
                    type: 'Produk',
                    is_free: 1,
                    fk_promotion_id: this.promosi.id ? this.promosi.id : 0
                })
            },
            hapusFree: function (index) {
                if (this.promosi.list_products_free[index].id) {
                    this.deletedItems.push(this.promosi.list_products_free[index].id)
                }

                if (this.promosi.list_products_free.length > 1)
                    this.promosi.list_products_free.splice(index, 1)
                else {
                    this.promosi.list_products_free[index].id = null
                    this.promosi.list_products_free[index].fk_product_id = 0
                    this.promosi.list_products_free[index].product_name = ''
                    this.promosi.list_products_free[index].category_name = ''
                    this.promosi.list_products_free[index].fk_product_category_id = 0
                    this.promosi.list_products_free[index].fk_promotion_id = this.promosi.id ? this.promosi.id : 0
                    this.promosi.list_products_free[index].is_free = 1
                    this.promosi.list_products_free[index].type = 'Produk'
                }


            },
            dataPromosi: function (id) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                this.isLoadData = true
                this.dialog = true
                axios
                ({method: 'get', url: ApiUrl + 'promotion/' + id, headers: header})
                    .then(response => {
                        console.log(response.data.data)
                        this.dialog = false
                        let d = response.data.data

                        if (response.data.success) {
                            this.promosi.id = d.id
                            this.promosi.name = d.name
                            this.promosi.desc = d.desc
                            this.promosi.type = d.type
                            this.promosi.is_all_item = d.is_all_item
                            this.promosi.percent = d.percent
                            this.promosi.max_amount = d.max_amount
                            this.promosi.amount = d.amount
                            this.promosi.buy = d.buy
                            this.promosi.get = d.get
                            this.dateFormattedMulai = this.formatDate(d.start_date)
                            this.dateFormattedSelesai = this.formatDate(d.end_date)
                            this.promosi.list_products = d.list_products
//                            this.promosi.start_date = new Date().toISOString().substr(0 10)
//                            this.promosi.end_date = new Date().toISOString().substr(0 10)
                        }
                    })
                    .catch(error => {
                        this.dialog = false
                        this.snackbar = true
                        this.color = 'error'
                        this.message = 'Gagal ambil data                    '
                        console.log(error)
                    })
            },
            simpan: function () {
                console.log(this.promosi)
                if (this.promosi.name && this.promosi.name !== '' && this.dateFormattedMulai && this.dateFormattedMulai !== '' &&
                    this.dateFormattedSelesai && this.dateFormattedSelesai !== '') {
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json',
                    }

                    this.promosi.max_amount = this.promosi.max_amount.replace(/\./g,'')
                    this.promosi.amount = this.promosi.amount.replace(/\./g,'')

                    let data = {
                        data: this.promosi,
                        delete: this.deletedItems,
                        promotion_products: []
                    };

                    this.promosi.list_products.forEach(function (item, i) {
                        if (item.fk_product_id != 0 || item.fk_product_category_id != 0) {
                            data.promotion_products.push({
                                id: null,
                                fk_product_id: item.fk_product_id,
                                fk_product_category_id: item.fk_product_category_id,
                                fk_promotion_id: item.fk_promotion_id,
                                is_free: item.is_free,
//                                type: item.type
                            })
                        }
                    });

                    this.promosi.list_products_free.forEach(function (item, i) {
                        if (item.fk_product_id != 0 || item.fk_product_category_id != 0) {
                            data.promotion_products.push({
                                id: null,
                                fk_product_id: item.fk_product_id,
                                fk_product_category_id: item.fk_product_category_id,
                                fk_promotion_id: item.fk_promotion_id,
                                is_free: item.is_free,
//                                type: item.type
                            })
                        }
                    });
                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'promotion/simpan', data: data, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil simpan                    '
                                this.promosi = [{
                                    id: null,
                                    list_products: [
                                        {
                                            id: null,
                                            fk_product_id: 0,
                                            fk_product_category_id: 0,
                                            product_name: '',
                                            category_name: '',
                                            type: 'Produk',
                                            fk_promotion_id: 0
                                        }
                                    ],
                                    list_products_free: [
                                        {
                                            id: null,
                                            fk_product_id: 0,
                                            fk_product_category_id: 0,
                                            product_name: '',
                                            category_name: '',
                                            type: 'Produk',
                                            fk_promotion_id: 0
                                        }
                                    ],
                                    name: '',
                                    desc: '',
                                    type: 'Disc Persen',
                                    is_all_item: false,
                                    percent: 0,
                                    max_amount: 0,
                                    amount: 0,
                                    buy: 0,
                                    get: 0,
                                    start_date: new Date().toISOString().substr(0, 10),
                                    end_date: new Date().toISOString().substr(0, 10),
                                }]
                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal simpan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Nama, tanggal mulai dan tanggal selesai harus diisi'
                }
            },
        }
    }
</script>
